import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Label from '../../../atoms/Label/index';
import TextInput from '../../../atoms/TextInput';
import DropDown from '../../../atoms/Dropdown';
import { SnackBarOptions, Dateformat } from '../../UserReport/DownloadableReport/CommonUtil';
import FormControl from '@mui/material/FormControl';
import Container from '@mui/material/Container';
import ACGButton from '../../../atoms/Button';
import dateFormat from 'dateformat';
import useGetState from '../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../constants/apiConstants';
import { Grid } from '@mui/material';
import { batch, useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../store/slice';
import CustomDatePicker from '../../../atoms/CustomDatePicker';

export default function CreateForm(props: any) {
  const dispatch = useDispatch()
  const date = new Date();
  const [inDate, setInDate] = useState<any>(date);
  const d = new Date();
  const [endDate, setEndDate] = useState(new Date(d.setDate(d.getDate())));
  const [errorDate, setErrorFalse] = useState(false);
  const [productKey, setProductKey] = useState<any>('');
  const [processKey, setProcessKey] = useState('');
  const [subProcessKey, setSubProcessKey] = useState('');
  const [recipeKey, setRecipeKey] = useState('');
  const [machineKey, setMachineKey] = useState('');
  const [batchKey, setBatchKey] = useState<any>('');
  const [masterBatchKey, setMasterBatchKey] = useState<any>('');
  const state = useGetState();
  const token = state?.[STORE_KEYS.USER_DTL].token;
  const getLoggedUserId = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;

  const options = {
    AD_SUCCESS: {
      message: `Golden Batch config ${props.selectedRow?.length !== 0 ? 'updated' : 'loaded'} successfully!`,
      open: true,
      type: 'success'
    },
    AD_FAIL: {
      message: `Filed to create Golden Batch`,
      open: true,
      type: 'remark'
    }
  };

  useEffect(() => {
    updatedDate();
  }, []);

  const updatedDate = () => {
    const date = new Date();
    setInDate(dateFormat(new Date(date.getFullYear(), date.getMonth(), 1), Dateformat.YYYY_MM_DD));
  }

  useEffect(() => {
    if (props.selectedRow?.length !== 0 && !props.isFilter) {
      if (props.machineModelName.includes("BQS")) {
        props.getMachine(props.selectedRow?.CustProductKey, props.selectedRow?.RecipeKey);
        setMachineKey(props.selectedRow?.MachineKey);
        setRecipeKey(props.selectedRow?.RecipeKey);
        setProductKey(props.selectedRow?.CustProductKey);
        setInDate(new Date(props.selectedRow?.BatchStartTime?.split(' ')[0]));
        setEndDate(new Date(props.selectedRow?.BatchEndTime?.split(' ')[0]));
        props.getBatches(props.selectedRow?.CustProductKey, props.selectedRow?.RecipeKey, props.selectedRow?.MachineKey, props.selectedRow?.BatchStartTime, props.selectedRow?.BatchEndTime);
        setBatchKey(props.selectedRow?.BatchKey);
      }
      else {
        const startLot = dateFormat(props.selectedRow?.LotStartDate?.split(' ')[0].split('|')[0].replace(/\s/g, ''), Dateformat.YYYY_MM_DD);
        const endLot = dateFormat(props.selectedRow?.LotEndDate?.split(' ')[0].split('|')[0].replace(/\s/g, ''), Dateformat.YYYY_MM_DD);

        setInDate(new Date(startLot));
        setEndDate(new Date(endLot));
        setProductKey(props.selectedRow?.CustProductKey);
        setProcessKey(props.selectedRow?.ProcessKey);
        setSubProcessKey(props.selectedRow?.SubProcessKey);
        props.getFBERecipes(props.selectedRow?.CustProductKey, props.selectedRow?.ProcessKey, props.selectedRow?.SubProcessKey, startLot, endLot, props.selectedRow?.MachineKey, props.selectedRow?.BatchNumber);
        props.getFBEMachine(props.selectedRow?.CustProductKey, props.selectedRow?.RecipeKey, startLot, endLot);
        setMachineKey(props.selectedRow?.MachineKey);
        props.getMasterBatches(props.selectedRow?.CustProductKey, props.selectedRow?.RecipeKey, startLot, endLot, props.selectedRow?.MachineKey);
        setMasterBatchKey(props.selectedRow?.BatchNumber);
        props.getBatches(props.selectedRow?.CustProductKey, props.selectedRow?.RecipeKey, props.selectedRow?.MachineKey, startLot, endLot,)
        props.getBatchesLot(props.selectedRow?.CustProductKey, props.selectedRow?.RecipeKey, startLot, endLot, props.selectedRow?.MachineKey, props.selectedRow?.BatchNumber);
        setBatchKey(props.selectedRow?.BatchKey);
        setRecipeKey(props.selectedRow?.RecipeKey);
      }
    }
  }, [props.selectedRow])

  const createGoldenBatchConfig = (type: any) => {
    if (props.machineModelName.includes('BQS')) {
      try {
        const bundle = {
          payload: {
            urlPath: ACTION_CODES.API_POST_GOLDENBATCH_CONFIG_LOAD,
            requestType: 'POST',
            reqObj: {
              LoggedUserKey: getLoggedUserId,
              RecipeKey: recipeKey,
              BatchKey: batchKey
            }
          },
          uniqueScreenIdentifier: { gbConfifAdded: true },
          storeKey: STORE_KEYS.API_POST_GOLDENBATCH_CONFIG_LOAD
        };
        dispatch(executeACGAction(bundle));

      } catch (err: any) {
        handleSnackbarError(err);
        props.drawerClose();
        props.setCurrTab(1);
      }
    } else {
      try {
        const bundle = {
          payload: {
            urlPath: ACTION_CODES.API_POST_GOLDENBATCH_CONFIG_FBE_LOAD,
            requestType: 'POST',
            reqObj: {
              LoggedUserKey: getLoggedUserId,
              CustProductKey: productKey,
              RecipeKey: recipeKey,
              BatchKey: batchKey,
              SubProcessKey: subProcessKey,
              AddEditActivity: type
            }
          },
          uniqueScreenIdentifier: { gbFBEConfifAdded: true },
          storeKey: STORE_KEYS.API_POST_GOLDENBATCH_CONFIG_FBE_LOAD
        };
        dispatch(executeACGAction(bundle));

      } catch (err: any) {
        handleSnackbarError(err);
        props.drawerClose();
        props.setCurrTab(1);
      }
    }
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_POST_GOLDENBATCH_CONFIG_FBE_LOAD]?.gbFBEConfifAdded) {
      props.setCurrTab(1);
      props.setSnackbarOpts(options.AD_SUCCESS);
      props.drawerClose();
      props.getGoldenBatchListFBE(props.machineModelKey, -1);
      props.getProducts();

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.API_POST_GOLDENBATCH_CONFIG_FBE_LOAD,
          uniqueScreenIdentifier: {
            gbFBEConfifAdded: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.API_POST_GOLDENBATCH_CONFIG_FBE_LOAD]?.gbFBEConfifAdded]);

  useEffect(() => {
    if (state?.[STORE_KEYS.API_POST_GOLDENBATCH_CONFIG_LOAD]?.gbConfifAdded) {
      props.setCurrTab(1);
      props.setSnackbarOpts(options.AD_SUCCESS);
      props.drawerClose();
      props.getProducts(5, 11, 'BQS_RETROFIT');
      props.getGoldenBatchListBQS();
      props.getGoldenRecipeBQS();
      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.API_POST_GOLDENBATCH_CONFIG_LOAD,
          uniqueScreenIdentifier: {
            gbConfifAdded: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.API_POST_GOLDENBATCH_CONFIG_LOAD]?.gbConfifAdded]);

  const handleSnackbarError = (err: any) => {
    const errorMsg = err?.message || 'Internal Server error';
    const snackbarError = {
      message: errorMsg,
      type: 'reject',
      open: true
    };
    props.setSnackbarOpts(snackbarError);
  };

  useEffect(() => {
    if (state?.err) {
      handleSnackbarError(state?.err);
      dispatch(resetErr());
    }
  }, [state?.err]);

  const handleInDateChange = (e: any) => {
    setInDate(new Date(e.toLocaleString().split(',')[0]))
    let ed = endDate.getTime();
    let sd = new Date(ed).getTime();
    if (ed < sd) {
      setErrorFalse(true);
    } else {
      setErrorFalse(false);
    }
  }

  const handleEndDateChange = (e: any) => {
    setEndDate(new Date(e.toLocaleString().split(',')[0]));
    let ed = new Date(e);
    let sd = inDate;
    if (ed < sd) {
      setErrorFalse(true);
    } else {
      setErrorFalse(false);
    }
  }

  const applyFilter = () => {
    props.setApplyListFilter(true);
    props.setFilteredProcessKey(processKey);
    props.getGoldenBatchListFBE(props.machineModelKey, processKey);
    props.drawerClose();
  }
  // LoggedUserKey: getLoggedUserId,
  // CustProductKey: productKey,
  // RecipeKey: recipeKey,
  // BatchKey: batchKey,
  // SubProcessKey: subProcessKey,
  // AddEditActivity: type
  const isDisabled = (props.selectedRow?.length === 0 && (recipeKey === "" || batchKey === "") && !props.isFilter) || (batchKey === "" && !props.isFilter) ? true : false

  const renderButtons = () => {
    console.log(subProcessKey,props)
    return (
      <div className={'btns noWrap'} style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <ACGButton
          name={'Cancel'}
          handleClick={props.drawerClose}
          secondary={true}
          className={'first-btn'}
          type="button"
          variant="contained"
        />
        <ACGButton
          name={'Submit'}
          handleClick={() => { !props.isFilter ? createGoldenBatchConfig(props.selectedRow?.length === 0 ? 'Add' : 'Edit') : applyFilter() }}
          type="submit"
          className="second-btn"
          variant="contained"
          disabled={isDisabled || (props.isFilter && (props.filteredProcessKey === ""))  }
        />
      </div>
    );
  };

  const productsChangeHandler = (e: any) => {
    props.getProcess(e.target.value)
    setProductKey(e.target.value);
    setProcessKey('');
    setSubProcessKey('');
    setRecipeKey('');
    updatedDate();
    setEndDate(new Date());
    setMachineKey('');
    props.setConfigMachineParams([]);
    setMasterBatchKey('');
    props.setConfigMasterBatches([]);
    setBatchKey('');
    props.setConfigBatches([]);
    if (props.machineModelName.includes('BQS')) props.getRecipes(e.target.value);
    else props.getFBERecipes(e.target.value);

  }

  return (
    <Container className="createReport filtrcrt">
      <Box>
        <div className='editdrawerGB'>
          {props.isFilter ?
            <FormControl fullWidth>
              <>

                <Label label="Process &nbsp; *" className={'CMlabelDiv'} />
                <DropDown
                  onChange={(e: any) => {
                    setProcessKey(e.target.value);
                    props.setFilteredProcessKey(e.target.value);
                  }}
                  className="inputDiv"
                  name="dropdownModel"
                  items={props.configMachineProcess}
                  placeHolder="Select Process"
                  value={props.filteredProcessKey}
                />

              </>
            </FormControl>
            :
            <FormControl fullWidth>
              <>
                <Label label="Products &nbsp; *" className={'CMlabelDiv'} />
                {
                  props.selectedRow?.length !== 0 ?
                    <TextInput
                      fullWidth
                      id="outlined-basic"
                      sx={{ margin: '0px', padding: '2px' }}
                      value={props.selectedRow?.ProductName}
                      disabled
                    />
                    :
                    <DropDown
                      onChange={(e: any) => { productsChangeHandler(e) }}
                      className="inputDiv"
                      name="dropdownModel"
                      items={props.productData}
                      placeHolder="Select Product"
                      value={productKey}
                    />
                }
                {
                  !props.machineModelName.includes("BQS") ?
                    <>
                      <Label label="Process &nbsp; *" className={'CMlabelDiv'} />
                      {
                        props.selectedRow?.length !== 0 ?
                          <TextInput
                            fullWidth
                            id="outlined-basic"
                            sx={{ margin: '0px', padding: '2px' }}
                            value={props.selectedRow?.ProcessName}
                            disabled
                          />
                          :
                          <DropDown
                            onChange={(e: any) => {
                              setProcessKey(e.target.value);
                              props.getSubProcess(e.target.value);
                              updatedDate();
                              setEndDate(new Date());
                              setSubProcessKey('');
                              setMachineKey('');
                              props.setConfigMachineParams([]);
                              setMasterBatchKey('');
                              props.setConfigMasterBatches([]);
                              setBatchKey('');
                              props.setConfigBatches([]);
                            }}
                            className="inputDiv"
                            name="dropdownModel"
                            items={props.configMachineProcess}
                            placeHolder="Select Process"
                            value={processKey}
                          />
                      }

                      <Label label="Sub Process &nbsp; *" className={'CMlabelDiv'} />
                      {
                        props.selectedRow?.length !== 0 ?
                          <TextInput
                            fullWidth
                            id="outlined-basic"
                            sx={{ margin: '0px', padding: '2px' }}
                            value={props.selectedRow?.SubProcessName}
                            disabled
                          />
                          :
                          <DropDown
                            onChange={(e: any) => {
                              setSubProcessKey(e.target.value);
                              updatedDate();
                              setEndDate(new Date());
                              setMachineKey('');
                              setMasterBatchKey('');
                              props.setConfigMasterBatches([]);
                              setBatchKey('');
                              props.setConfigBatches([]);
                            }}
                            className="inputDiv"
                            name="dropdownModel"
                            items={props.configMachineSubProcess}
                            placeHolder="Select Sub Process"
                            value={subProcessKey}
                          />
                      }

                      <Label label="Recipes. &nbsp;* " className={'CMlabelDiv'} />
                      {
                        props.selectedRow?.length !== 0 ?
                          <TextInput
                            fullWidth
                            id="outlined-basic"
                            sx={{ margin: '0px', padding: '2px' }}
                            value={props.selectedRow?.RecipeName}
                            disabled
                          />
                          :
                          <DropDown
                            onChange={(e: any) => {
                              setRecipeKey(e.target.value);
                              props.getFBEMachine(productKey, e.target.value, inDate, endDate);
                              setMachineKey('');
                            }}
                            className="inputDiv"
                            name="dropdownModel"
                            items={props.configMachineRecipes}
                            placeHolder="Select Recipe"
                            value={recipeKey}
                          />
                      }

                      <Label label="Start Date &nbsp; *" className={'CMlabelDiv'} />
                      <CustomDatePicker
                        name="startDate"
                        value={inDate}
                        className="inputDiv"
                        onChange={(val: any) => {
                          handleInDateChange(val);
                          props.getFBEMachine(productKey, recipeKey, val.toLocaleString().split(',')[0], endDate);
                          setMachineKey('');
                          setMasterBatchKey('');
                          props.setConfigMasterBatches([]);
                          setBatchKey('');
                          props.setConfigBatches([]);
                        }}
                        inputFormat="dd/MM/yyyy"
                        placeholder="Select Start Date"
                        maxDate={new Date()}
                      />
                      <Label label="End Date &nbsp; *" className={'CMlabelDiv'} />
                      <CustomDatePicker
                        name="endDate"
                        value={endDate}
                        className="inputDiv"
                        onChange={(val: any) => {
                          handleEndDateChange(val);
                          props.getFBEMachine(productKey, recipeKey, inDate, val.toLocaleString().split(',')[0]);
                          setMachineKey('');
                          setMasterBatchKey('');
                          props.setConfigMasterBatches([]);
                          setBatchKey('');
                          props.setConfigBatches([]);
                        }}
                        inputFormat="dd/MM/yyyy"
                        placeholder="Select End Date"
                        minDate={inDate}
                        maxDate={new Date()}
                      />

                      <Label label="Machine Serial No. &nbsp;* " className={'CMlabelDiv'} />
                      <DropDown
                        onChange={(e: any) => {
                          setMachineKey(e.target.value);
                          props.getMasterBatches(productKey, recipeKey, inDate, endDate, e.target.value);
                          setMasterBatchKey('');
                          setBatchKey('');
                          props.setConfigMasterBatches([]);
                          props.setConfigBatches([]);
                        }}
                        className="inputDiv"
                        name="dropdownModel"
                        items={props.configMachineParams}
                        placeHolder="Select Serial No."
                        value={machineKey}
                      />

                      <Label label="Master Batches. &nbsp;* " className={'CMlabelDiv'} />
                      <DropDown
                        onChange={(e: any) => {
                          props.getBatchesLot(productKey, recipeKey, inDate, endDate, machineKey, e.target.value);
                          setMasterBatchKey(e.target.value);
                          setBatchKey('');
                          props.setConfigBatches([]);
                        }}
                        className="inputDiv"
                        name="dropdownModel"
                        items={props.configMasterBatches}
                        placeHolder="Select Master Batch No."
                        value={masterBatchKey}
                      />

                      <Label label="Lot Batches. &nbsp;* " className={'CMlabelDiv'} />
                      <DropDown
                        onChange={(e: any) => {
                          setBatchKey(e.target.value);
                        }}
                        className="inputDiv"
                        name="dropdownModel"
                        items={props.configBatches}
                        placeHolder="Select Lot Batches"
                        value={batchKey}
                      />
                    </>
                    :

                    <>
                      <Label label="Recipes &nbsp; *" className={'CMlabelDiv'} />
                      {
                        props.selectedRow?.length !== 0 ?
                          <TextInput
                            fullWidth
                            id="outlined-basic"
                            sx={{ margin: '0px', padding: '2px' }}
                            value={props.selectedRow?.RecipeName}
                            disabled
                          />
                          :
                          <DropDown
                            onChange={(e: any) => { setRecipeKey(e.target.value); props.getMachine(productKey, e.target.value); props.getBatches(productKey, e.target.value, machineKey, inDate, endDate); updatedDate(); setEndDate(new Date()); setBatchKey(''); }}
                            className="inputDiv"
                            name="dropdownModel"
                            items={props.configMachineRecipes}
                            placeHolder="Select Recipe"
                            value={recipeKey}
                          />
                      }
                      <Label label="Machine Serial No. &nbsp; * " className={'CMlabelDiv'} />
                      <DropDown
                        onChange={(e: any) => { setMachineKey(e.target.value); props.getBatches(productKey, recipeKey, e.target.value, inDate, endDate); updatedDate(); setEndDate(new Date()); setBatchKey(''); }}
                        className="inputDiv"
                        name="dropdownModel"
                        items={props.configMachineParams}
                        placeHolder="Select Serial No."
                        value={machineKey}
                      />
                      <Label label="Start Date &nbsp; *" className={'CMlabelDiv'} />
                      <CustomDatePicker
                        name="startDate"
                        value={inDate}
                        className="inputDiv"
                        onChange={(val: any) => {
                          handleInDateChange(val);
                          setBatchKey('');
                          props.getBatches(productKey, recipeKey, machineKey, val.toLocaleString().split(',')[0], endDate);
                        }}
                        disabled={recipeKey ? false : true}
                        inputFormat="dd/MM/yyyy"
                        placeholder="Select Start Date"
                        maxDate={new Date()}
                      />
                      <Label label="End Date &nbsp; *" className={'CMlabelDiv'} />
                      <CustomDatePicker
                        name="endDate"
                        value={endDate}
                        className="inputDiv"
                        onChange={(val: any) => {
                          handleEndDateChange(val);
                          setBatchKey('');
                          props.getBatches(productKey, recipeKey, machineKey, inDate, val.toLocaleString().split(',')[0])
                        }}
                        disabled={recipeKey ? false : true}
                        inputFormat="dd/MM/yyyy"
                        placeholder="Select End Date"
                        minDate={inDate}
                        maxDate={new Date()}
                      />
                      <Label label="Batches &nbsp; * " className={'CMlabelDiv'} />
                      <DropDown
                        onChange={(e: any) => { setBatchKey(e.target.value); }}
                        className="inputDiv"
                        name="dropdownModel"
                        items={props.configBatches}
                        placeHolder="Select Batch"
                        value={batchKey}
                      />
                    </>
                }
              </>
            </FormControl>
          }
        </div>
      </Box>
      <Grid style={{ display: "flex", marginTop: "-8px", marginBottom: "110px" }}>
        {renderButtons()}
      </Grid>

    </Container>
  );
}