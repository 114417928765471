import { useState, useEffect } from 'react';
import Axios from 'axios';
import { Line } from "react-chartjs-2";
import useGetState from '../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../constants/apiConstants';
import Button from '@mui/material/Button';
import DropDown from '../../../atoms/Dropdown';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './Index.scss';
import { trackErrorsInNewRelic } from '../../../utils/newRelicFuncations';
type ComponentProps = {
  allFilters: any;
  temperatureFilters: any;
  bKey: string | number;
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2e304a',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const CCPReport = (props: ComponentProps) => {
  const { temperatureFilters, allFilters, bKey } = props;
  const [tempParam1, setTempParam1] = useState('');
  const [tempParam2, setTempParam2] = useState('');
  const [allParam1, setAllParam1] = useState('');
  const [allParam2, setAllParam2] = useState('');
  const [temparatureChartData, setTemperatureChartData] = useState<any[] | undefined | any>([]);
  const [temp1Value, setTemp1Value] = useState([]);
  const [temp2Value, setTemp2Value] = useState([]);
  const [tempDiffData, settempDiffData] = useState([]);
  const [allChartData, setAllChartData] = useState<any[] | undefined | any>([]);
  const [all1Value, setAll1Value] = useState([]);
  const [all2Value, setAll2Value] = useState([]);
  const [chart1Page, setChart1Page] = useState<number>(1);
  const [chart2Page, setChart2Page] = useState<number>(1);
  const [timestamparr, setTimestamparr] = useState([]);
  const [timestamparr2, setTimestamparr2] = useState([]);
  const [graphdataarr, setGraphdataarr] = useState([]);
  const graphUnits = "";
  const state = useGetState();
  const token = state?.[STORE_KEYS.USER_DTL].token;
  function createData(
    name: string,
    parameter1: number,
    parameter2: number,
  ) {
    return { name, parameter1, parameter2 };
  }

  function Unit() {
    if (graphUnits === undefined || graphUnits === null || graphUnits === "") {
      return "°C";
    } else {
      return graphUnits;
    }
  }

  const UnitTxt = Unit();

  const rows = [
    createData('Min', 159, 6.0),
    createData('Max', 237, 9.0),
    createData('Average', 262, 16.0),
  ];

  const handleChangeParameters = async (event: any, type: string) => {
    if (type === "temp1") {
      setTempParam1(event.target.value);
      if (tempParam2 === '') return;
      const data = await getGraphdata(chart1Page, event.target.value, tempParam2, "temp_chart1", 0, "+", false);
      setTemperatureChartData(data);
      formatGraphData("temp_chart1", data);
    } else if (type === "temp2") {
      setTempParam2(event.target.value);
      if (tempParam1 === '') return;
      const data = await getGraphdata(chart1Page, tempParam1, event.target.value, "temp_chart1", 0, "+", false)
      setTemperatureChartData(data);
      formatGraphData("temp_chart1", data);
    } else if (type === "all1") {
      setChart2Page(1);
      setAllParam1(event.target.value);
      if (allParam2 === '') return;
      const data = await getGraphdata(chart2Page, event.target.value, allParam2, "all_chart2", 0, "+", false)
      setAllChartData(data);
      formatGraphData("all_chart2", data);
    } else {
      setChart2Page(1);
      setAllParam2(event.target.value);
      if (allParam1 === '') return;
      const data = await getGraphdata(chart2Page, allParam1, event.target.value, "all_chart2", 0, "+", false)
      setAllChartData(data);
      formatGraphData("all_chart2", data);
    }
    if (type === "temp1" || type === "temp2") {
      setChart1Page(1);
    } else setChart2Page(1);
  }

  const getGraphdata = async (pageNo: number, param1: any, param2: any, type: string, count: number, operator: string, pagination: boolean) => {
    if (type === "temp_chart1") {
      if (operator === '+') {
        setChart1Page(pageNo + count);
      } else setChart1Page(pageNo - count)
    } else {
      if (operator === '+') {
        setChart2Page(pageNo + count);
      } else setChart2Page(pageNo - count)
    }
    let result;
    const payload = {
      batchKey: bKey,
      param1: param1,
      param2: param2,
      type: type,
      page: !pagination ? 1 : pageNo
    };
    await Axios({
      url: BASEURL + '/powerbi/cpp-analysis-filter',
      method: 'post',
      data: payload,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(async (response) => {
        if (response.status == 200 && response?.data) {
          result = await response.data.data;
        }
      })
      .catch((error) => {
        trackErrorsInNewRelic({error: error})

        return {
          status: error.response.status
        };
      });
    return result;
  }

  const formatGraphData = (type: string, data: any) => {
    const ts: any = [], p1: any = [], p2: any = [], dif: any = [];
    data.map((i: any) => {
      ts.push(i.EventTime.split(".")[0]);
      p1.push(i.Param1);
      p2.push(i.Param2);
      dif.push(i.Difference?.split('°')[0]);
    })

    if (type === "temp_chart1") {
      setTimestamparr(ts);
      setTemp1Value(p1);
      setTemp2Value(p2);
      settempDiffData(dif);
    } else {
      setGraphdataarr(ts);
      setAll1Value(p1);
      setAll2Value(p2);
    }
  }

  const lastPage = temparatureChartData?.length && temparatureChartData[0]?.TotalPages;
  const lastPage2 = allChartData?.length && allChartData[0]?.TotalPages;
  const chart1NextPageConditionCheck = ((tempParam1 === '' || tempParam2 === '') || (lastPage === chart1Page)) ? true : false;
  const chart2NextPageConditionCheck = ((allParam1 === '' || allParam2 === '') || (lastPage2 === chart2Page)) ? true : false;

  const pagination = async (type: string, count: number, operator: string) => {
    if (type === "temp_chart1") {
      const data = await getGraphdata(chart1Page, tempParam1, tempParam2, type, count, operator, true);
      setTemperatureChartData(data);
      formatGraphData("temp_chart1", data);
    } else {
      const data = await getGraphdata(chart2Page, allParam1, allParam2, type, count, operator, true);
      setAllChartData(data);
      formatGraphData("all_chart2", data);
    }
  }

  const graphColor = {
    actual: "#95C3FE",
    max: "#C26E68",
    min: "#ECA546",
    setValue: "#8BCD9D",
    tooltipBG: "#fff",
    tooltipText: "#f1f1f1",
  };

  const tempparam1 = {
    label: tempParam1 ? temperatureFilters.filter((i: { code: string; }) => i.code === tempParam1)[0].name : 'Not selected',
    fill: false,
    lineTension: 0.1,
    backgroundColor: graphColor.actual,
    borderColor: graphColor.actual,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: graphColor.actual,
    pointBackgroundColor: graphColor.actual,
    pointBorderWidth: 0,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: graphColor.actual,
    pointHoverBorderColor: graphColor.actual,
    pointHoverBorderWidth: 0,
    pointRadius: 1,
    // pointHitRadius: 10,
    data: temp1Value,
  };

  const tempparam2 = {
    // C26E68
    label: tempParam2 ? temperatureFilters.filter((i: { code: string; }) => i.code === tempParam2)[0].name : "Not selected",
    fill: false,
    lineTension: 0.1,
    backgroundColor: graphColor.max,
    borderColor: graphColor.max,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    // borderJoinStyle: "miter",
    pointBorderColor: graphColor.max,
    pointBackgroundColor: graphColor.max,
    pointBorderWidth: 0,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: graphColor.max,
    pointHoverBorderColor: graphColor.max,
    pointHoverBorderWidth: 0,
    pointRadius: 1,
    pointHitRadius: 10,
    data: temp2Value,
  };
  const tempdiff = {
    label: "Difference",
    fill: false,
    lineTension: 0.1,
    backgroundColor: 'hsla(0,0%,0%,0.0)',
    borderColor: 'hsla(0,0%,0%,0.0)',
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: 'hsla(0,0%,0%,0.0)',
    pointBackgroundColor: 'red',
    pointBorderWidth: 0,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: 'hsla(0,0%,0%,0.0)',
    pointHoverBorderColor: 'hsla(0,0%,0%,0.0)',
    pointHoverBorderWidth: 0,
    pointRadius: 0.1,
    pointHitRadius: 10,
    data: tempDiffData,

  };

  const allparam1 = {
    label: allParam1 ? allFilters.filter((i: { code: string; }) => i.code === allParam1)[0].name : 'Not selected',
    fill: false,
    lineTension: 0.1,
    backgroundColor: graphColor.actual,
    borderColor: graphColor.actual,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: graphColor.actual,
    pointBackgroundColor: graphColor.actual,
    pointBorderWidth: 0,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: graphColor.actual,
    pointHoverBorderColor: graphColor.actual,
    pointHoverBorderWidth: 0,
    pointRadius: 1,
    // pointHitRadius: 10,
    data: all1Value,

  };

  const allparam2 = {
    // C26E68
    label: allParam2 ? allFilters.filter((i: { code: string; }) => i.code === allParam2)[0].name : "Not selected",
    fill: false,
    lineTension: 0.1,
    backgroundColor: graphColor.max,
    borderColor: graphColor.max,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    // borderJoinStyle: "miter",
    pointBorderColor: graphColor.max,
    pointBackgroundColor: graphColor.max,
    pointBorderWidth: 0,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: graphColor.max,
    pointHoverBorderColor: graphColor.max,
    pointHoverBorderWidth: 0,
    pointRadius: 1,
    pointHitRadius: 10,
    data: all2Value,
  };

  const data : any= {
    labels: timestamparr,
    datasets: [tempparam1, tempparam2, tempdiff]

  };

  const data2 : any = {
    labels: timestamparr,
    datasets: [allparam1, allparam2]
  };

  const Option: any = {
    interaction: {
      mode: "index",
      intersect: false,
    },
    legend: {
      display: true,
      position: "top",
      labels: {
        boxWidth: 10,
        boxHeight: 10,
      }
    },
    tooltips: {
      position: "nearest",
      backgroundColor: graphColor.tooltipBG,
      titleFontColor: graphColor.tooltipText,
      bodyFontColor: graphColor.tooltipText,
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {

          scaleLabel: {
            display: true,
            labelString: "°C",
            position: "left"
          },
          gridLines: {
            color: "#FFFFFF"
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 20,
          },
        },
      ],

      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Time",
          },
          gridLines: {
            color: "#DADADA",
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 20,
            padding: 15
          },
        },
      ],
    },
  };

  return (
    <div className='mainarea'>
      <div className='leftcontent'>
        <div className='wrapdivs'>
          <div className='graph_div'>
            <div className='selectparm selectt'>
              <label>Select Parameters Types</label>
              <div className='dropparam dropp'>
                <DropDown
                  onChange={(e) => { handleChangeParameters(e, 'temp1') }}
                  className="inputDiv dropdownbx"
                  name="dropdownModel"
                  items={temperatureFilters.filter((i: { code: string; }) => i.code !== tempParam2)}
                  placeHolder="Select Parameter 1"
                  value={tempParam1}
                />
                <DropDown
                  onChange={(e) => handleChangeParameters(e, 'temp2')}
                  className="inputDiv dropdownbx"
                  name="dropdownModel"
                  items={temperatureFilters.filter((i: { code: string; }) => i.code !== tempParam1)}
                  placeHolder="Select Parameter 2"
                  value={tempParam2}
                />
              </div>
            </div>
            <div className='graphcontent'>
              <Line
                data={data}
                height={140}
                options={Option}
              />
            </div>
            <div className='buttonwrap'>
              <Button variant="contained" size="small" style={chart1Page === 1 ? { padding: '10px' } : {color: '#e0e0e0', textTransform: 'capitalize', lineHeight: '1', padding: '10px 10px' }} disabled={chart1Page === 1 ? true : false} onClick={() => pagination("temp_chart1", 1, '-')}>
                Previous
              </Button>
              <Button variant="contained" size="small" style={chart1NextPageConditionCheck ? { padding: '10px' } : {color: '#e0e0e0', textTransform: 'capitalize', lineHeight: '1', padding: '10px 10px' }} disabled={chart1NextPageConditionCheck ? true : false} onClick={() => pagination("temp_chart1", 1, '+')}>
                Next
              </Button>
            </div>
          </div>
          <div className='table_div'>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 100 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Name</StyledTableCell>
                    <StyledTableCell align="center">Parameter 1</StyledTableCell>
                    <StyledTableCell align="center">Parameter 2</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key={"Min"}>
                      <StyledTableCell align="center">Min</StyledTableCell>
                      <StyledTableCell align="center">{temparatureChartData[0]?.Param1MIN}</StyledTableCell>
                      <StyledTableCell align="center">{temparatureChartData[0]?.Param2MIN}</StyledTableCell>
                    </StyledTableRow>
                     <StyledTableRow key={"Max"}>
                      <StyledTableCell align="center">Max</StyledTableCell>
                      <StyledTableCell align="center">{temparatureChartData[0]?.Param1MAX}</StyledTableCell>
                      <StyledTableCell align="center">{temparatureChartData[0]?.Param2MAX}</StyledTableCell>
                    </StyledTableRow>
                      <StyledTableRow key={"Avg"}>
                      <StyledTableCell align="center">Avg</StyledTableCell>
                      <StyledTableCell align="center">{temparatureChartData[0]?.Param1AVG}</StyledTableCell>
                      <StyledTableCell align="center">{temparatureChartData[0]?.Param2AVG}</StyledTableCell>
                    </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className='wrapdivs'>
          <div className='graph_div' style={{borderTop: "10px solid #24263a"}}>
            <div className='selectparm selectt'>
              <label>Select Parameters Types</label>
              <div className='dropparam dropp'>
                <DropDown
                  onChange={(e) => handleChangeParameters(e, 'all1')}
                  className="inputDiv dropdownbx"
                  name="dropdownModel"
                  items={allFilters.filter((i: { code: string; }) => i.code !== allParam2)}
                  placeHolder="Select Parameter 1"
                  value={allParam1}
                />
                <DropDown
                  onChange={(e) => handleChangeParameters(e, 'all2')}
                  className="inputDiv dropdownbx"
                  name="dropdownModel"
                  items={allFilters.filter((i: { code: string; }) => i.code !== allParam1)}
                  placeHolder="Select Parameter 2"
                  value={allParam2}
                />
              </div>
            </div>
            <div className='graphcontent'>
            <Line
                data={data2}
                height={140}
                options={Option}
              />
            </div>
            <div className='buttonwrap'>
              <Button variant="contained" size="small" style={chart2Page === 1 ? {padding: '10px'} : { background: '#5d97f6', color: '#e0e0e0', textTransform: 'capitalize', lineHeight: '1', padding: '10px 10px' }} disabled={chart2Page === 1 ? true : false} onClick={() => pagination("all_chart2", 1, '-')}>
                Previous
              </Button>
              <Button variant="contained" size="small" style={chart2NextPageConditionCheck ? {padding: '10px'} : { background: '#5d97f6', color: '#e0e0e0', textTransform: 'capitalize', lineHeight: '1', padding: '10px 10px' }} disabled={chart2NextPageConditionCheck ? true : false} onClick={() => pagination("all_chart2", 1, '+')}>
                Next
              </Button>
            </div>
          </div>
          <div className='table_div' style={{borderTop: "10px solid #24263a"}}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 100 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Name</StyledTableCell>
                    <StyledTableCell align="center">Parameter 1</StyledTableCell>
                    <StyledTableCell align="center">Parameter 2</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key={"Min"}>
                      <StyledTableCell align="center">Min</StyledTableCell>
                      <StyledTableCell align="center">{allChartData[0]?.Param1MIN}</StyledTableCell>
                      <StyledTableCell align="center">{allChartData[0]?.Param2MIN}</StyledTableCell>
                    </StyledTableRow>
                     <StyledTableRow key={"Max"}>
                      <StyledTableCell align="center">Max</StyledTableCell>
                      <StyledTableCell align="center">{allChartData[0]?.Param1MAX}</StyledTableCell>
                      <StyledTableCell align="center">{allChartData[0]?.Param2MAX}</StyledTableCell>
                    </StyledTableRow>
                      <StyledTableRow key={"Avg"}>
                      <StyledTableCell align="center">Avg</StyledTableCell>
                      <StyledTableCell align="center">{allChartData[0]?.Param1AVG}</StyledTableCell>
                      <StyledTableCell align="center">{allChartData[0]?.Param2AVG}</StyledTableCell>
                    </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

    </div>
  )
}

export default CCPReport;