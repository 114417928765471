import { useState, useEffect, useRef } from 'react';
import { Container } from '@mui/material';
import useGetState from '../../../../app/utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../app/constants/apiConstants';
import Axios from 'axios';
import { formatOnlyDate } from '../../../utils/formatTime';
import { Grid, Card, CardContent, CardActions, Typography, Button, Box, IconButton } from '@mui/material';
import SnackbarAlert from '../../../atoms/Snackbar';
import DownArrow from '../../../../assets/down-arrow.svg';
import Breadcrumb from '../../../atoms/BreadCrumb';
import DropDown from '../../../atoms/Dropdown';
import ACGButton from '../../../atoms/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CustomDatePicker from '../../../atoms/CustomDatePicker';
import '../../OEEDashboard/OEEInsights/OEEInsights.scss';
import Prompter from '../../../atoms/Prompter';
import AlertBox from '../../../atoms/AlertBox';
import InfoIcon from '@mui/icons-material/Info';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import RefreshIcon from '@mui/icons-material/Refresh';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

const AI_API_BASE_URL = "http://172.16.123.78:7010"

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const Dateformat = {
    YYYY_MM_DD: 'yyyy-mm-dd',
    YYYY_MM_DD_HH_MM_SS: 'yyyy-mm-dd hh:mm:ss',
    YYYY_MM_DD_HH_MM_SS_L: 'yyyy-mm-ddTHH:MM:ss.l',
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const DropDownIcon = (prop: any) => (
    <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
);
const OEEInsights = (props: any) => {
    const componentRef = useRef<any>();
    const [showLoader, setShowLoader] = useState(false);
    const [machineModel, setMachineModel] = useState<any>([]);
    const [selectedMachineModel, setSelectedMachineModel] = useState<any>('');
    const [machines, setMachines] = useState<any>([]);
    const [selectedMachines, setSelectedMachines] = useState<any>([]);
    const [aIInsightsList, setAIInsightsList] = useState<any>([]);
    const [selectedInsight, setSelectedInsight] = useState<any>('');
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const [right, setRight] = useState(false);
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const email = state[STORE_KEYS.USER_DTL]?.EmailId;
    const AIInsightsShow = state[STORE_KEYS.USER_DTL]?.AIInsightVisibleFlag;
    const date = new Date();
    date.setDate(date.getDate() - 90)
    const [inDate, setInDate] = useState(date);
    const d = new Date();
    const [endDate, setEndDate] = useState(new Date());
    const [defaultData, setDefaultData] = useState<any>([]);
    const [tab, setTab] = useState(0);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [applyFilter, setApplyFilter] = useState(false);
    const [isDefault, setIsDefult] = useState(true);
    const [canDownload, setCanDownload] = useState(false);

    useEffect(() => {
        (async () => {
            const payload = {
                userId: loggedInUser,
                plantKey: 'All'
            };
            await Axios({
                url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_MACHINE_MODEL,
                method: 'post',
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then(async (response) => {
                    if (response.status == 200 && response?.data) {
                        const result = response?.data?.message;
                        const tmpTableData = result[0][0];
                        setMachineModel([
                            ...tmpTableData?.map((item: any) => ({ code: item.MachineModelKey, name: item.MachineModelName }))])
                        setSelectedMachineModel(tmpTableData[0]?.MachineModelKey);
                        fetchMachines(tmpTableData[0]?.MachineModelKey);
                        await fetchAIInsightsList(tmpTableData[0]?.MachineModelKey);
                    }

                })
                .catch((error) => {
                    if (window.newrelic) {
                        window.newrelic.noticeError(error?.message);
                    }
                    return {
                        status: error.response.status
                    };
                });

        })();
    }, []);

    const fetchMachines = async (model: any) => {
        const payload = {
            userId: loggedInUser,
            model: model,
            plantKey: 'All'
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_MACHINES,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setMachines([
                        ...tmpTableData?.map((item: any) => ({ code: item.MachineSerialNo, name: item.MachineName }))])
                    setSelectedMachines(tmpTableData.map((i: any) => i.MachineSerialNo));
                }
            })
            .catch((error) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    const fetchAIInsightsList = async (model: any) => {
        const payload = {
            loggedInUserKey: loggedInUser,
            machineModelKey: model,
            plantKey: 'All'
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_AI_INSIGHTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setAIInsightsList([
                        ...tmpTableData?.map((item: any) => ({ code: item.PromptCode, name: item.PromptName }))])
                    setSelectedInsight(tmpTableData[0]?.PromptCode);

                }
            })
            .catch((error) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    useEffect(() => {
        if (selectedInsight != "" && selectedMachines.length > 0 && isDefault) {
            fetchDefaultInsights(selectedInsight, selectedMachines);
        }
    }, [selectedInsight, selectedMachines])

    const fetchDefaultInsights = async (category: any, model: any, appliedFilter = false) => {
        setShowLoader(true);
        const payload = {
            start_date: formatOnlyDate(inDate),
            end_date: formatOnlyDate(endDate),
            machine_family: model,
            insight_category: category
        };

        await Axios({
            url: BASEURL + '/genaiapp/chat/descriptive_insights',
            method: 'post',
            data: payload,
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const aiData = response?.data;
                    aiData['isUser'] = false;
                    const obj = { [category]: [aiData] }
                    const isCategoryPresent = defaultData.some((item: any) => item.hasOwnProperty(category));
                    if (!isCategoryPresent) {
                        setDefaultData([...defaultData, obj]);
                    }
                    setIsDefult(false);
                    setShowLoader(false);
                }
            })
            .catch((error) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    }

    const fetchChatInsights = async (qs: any) => {
        setShowLoader(true);
        await new Promise(resolve => setTimeout(resolve, 3000));

        const payload = {
            start_date: formatOnlyDate(inDate),
            end_date: formatOnlyDate(endDate),
            machine_family: selectedMachines,
            // insight_category: selectedInsight,
            question: qs
        };

        try {
            const response = await Axios({
                url: BASEURL + '/genaiapp/chat/descriptive_insights_free_text',
                method: 'post',
                data: payload,
            });

            if (response.status === 200) {
                setShowLoader(false);
                const aiData = response.data;
                aiData['isUser'] = false;
                aiData['feedback'] = null;
                return aiData;
            } else {
                return { error: 'No data received' };
            }
        } catch (error: any) {
            
            if (window.newrelic) {
                window.newrelic.noticeError(error?.message);
            }
            return { status: 'error', message: error.message || 'Unknown error' }; // Return error details
        }
    };

    const refreshInights = async (isFilter = false) => {
        setDefaultData([]);
        setShowLoader(true);

        const formData = new FormData();
        formData.append("insight_category", selectedInsight);
        await Axios({
            url: BASEURL + '/genaiapp/chat/create_thread',
            method: 'post',
            data: formData,
            headers: { "Content-Type": "multipart/form-data" } 
        })
            .then(async (response) => {
                if (response.status == 200) {
                    setShowLoader(false);
                    const aiData: any = [];
                    aiData['isUser'] = false;
                    const obj = { [selectedInsight]: [] }
                    const isCategoryPresent = defaultData.some((item: any) => item.hasOwnProperty(selectedInsight));
                    if (!isCategoryPresent) {
                        setDefaultData([...defaultData, obj]);
                    } else {
                        setDefaultData([obj]);
                    }

                }
            })
            .catch((error) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error?.message);
                }
                return {
                    status: error
                };
            });
    };

    const handleCheckboxChange = (id: any) => {
        let data: any;
        setSelectedMachines((prevSelected: any) => {
            if (prevSelected.includes(id)) {
                data = prevSelected.filter((optionId: any) => optionId !== id);
                return prevSelected.filter((optionId: any) => optionId !== id);
            } else {
                data = [...prevSelected, id];
                return [...prevSelected, id];
            }
        });
    };

    const handleSelectAll = () => {
        if (selectedMachines.length === machines.length) {
            setSelectedMachines([]);
        } else {
            const data = machines.map((option: any) => option.code)
            setSelectedMachines(data);
        }
    };

    const handleDialogClose: React.MouseEventHandler<HTMLElement> = (e) => closeRmBx(e, 'close');

    const closeRmBx = (event: object, reason: string = "") => {
        if (reason === "close") return;
        setApplyFilter(false);
        setRemoveBxOpen(false);
    };

    const applyFilterHandler = () => {
        setApplyFilter(false);
        setRemoveBxOpen(true);
        setIsDefult(true);
    }

    useEffect(() => {
        const applyFilters = async () => {
            if (!canDownload) {
                await fetchDefaultInsights(selectedInsight, selectedMachines, true);
            }
            setRemoveBxOpen(false);
        };

        if (applyFilter) {
            applyFilters().then(() => setApplyFilter(false));
        }
    }, [applyFilter]);

    const rightBtnPress = async () => {
        await handleDownloadPdf();
        setDefaultData([]);
        setCanDownload(true);
    };

    useEffect(() => {
        if (canDownload && defaultData.length === 0) {
            fetchDefaultInsights(selectedInsight, selectedMachines, true);
            setCanDownload(false); // Reset the flag after fetching insights
        }
    }, [defaultData, canDownload]);

    const handleDownloadPdf = async () => {
        const element = componentRef.current;
    
        // Style adjustments for PDF rendering
        const originalFontSize = element.style.fontSize;
        const originalHeight = element.style.height;
        const originalOverflow = element.style.overflow;
        element.style.fontSize = '8px';
        element.style.height = `${element.scrollHeight}px`;
        element.style.overflow = 'unset';
    
        // Convert each image to base64
        const images = element.querySelectorAll('img');
        await Promise.all(Array.from(images).map(async (img:any) => {
            if (img.src.includes('172.16.123.78:7010')) {
                img.src = img.src.replace('http://172.16.123.78:7010', window.location.origin);
            }
            if (!img.complete) {
                await new Promise((resolve) => {
                    img.onload = resolve;
                    img.onerror = resolve;
                });
            }
        }));
    
        try {
            // Generate PDF with html2canvas
            const canvas = await html2canvas(element, {
                backgroundColor: null,
                scale: 2,
                scrollX: 0,
                scrollY: 0,
                useCORS: true,
                allowTaint: false,
            });
    
            const imageData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
    
            let imgHeight = (canvas.height * pageWidth) / canvas.width;
            let position = 0;
    
            while (position < imgHeight) {
                pdf.addImage(imageData, 'PNG', 0, -position, pageWidth, imgHeight);
                position += pageHeight;
                if (position < imgHeight) {
                    pdf.addPage();
                }
            }
    
            const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
            pdf.save(`AI-insights_${timestamp}.pdf`);
    
        } finally {
            // Restore original styles
            element.style.fontSize = originalFontSize;
            element.style.height = originalHeight;
            element.style.overflow = originalOverflow;
        }
    };
    
    const breadcrumbData = [
        { label: "Home", url: "/oee-dashboard" }];

    if (AIInsightsShow === "Y") {
        breadcrumbData.push({ label: "AI Insights", url: "/oee-insights" });
    }

    return (
        <div className="dashboard OEEInsights" style={{ minHeight: 120 }}>
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div className="headinglabel mb10" style={{ marginBottom: '15px' }}>
                    AI - Insights
                </div>
                <Breadcrumb
                    data={breadcrumbData}
                />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                        className={right ? 'content-narrow' : 'content-expanded'}
                        sx={{
                            width: right ? '75%' : '100%',
                            transition: 'width 0.3s',
                            p: 0
                        }}
                    >
                        {!right && (
                            <div className='arrowSwap arrowBack'>
                                <ChevronLeftIcon onClick={() => setRight(true)} style={{ cursor: 'pointer' }} />
                            </div>
                        )}
                        <div className='leftBox'>
                            <div className='cardbox'>
                                <Grid
                                    container
                                    spacing={2}
                                    className="cardGrid"
                                    justifyContent="flex-start"
                                    wrap="nowrap"
                                    style={{ overflowX: 'auto', flexWrap: 'nowrap', paddingBottom: '0px' }}
                                >
                                    {
                                        aIInsightsList?.map((item: any, i: number) => {
                                            return (
                                                <Grid item xs={12} sm={6} md={4} lg={2.4} className="cardInner">
                                                    <Card sx={{ minWidth: 156, cursor: 'pointer' }} onClick={() => { setSelectedInsight(item.code); setTab(i); setIsDefult(true); }}>
                                                        <CardContent className={item.code === selectedInsight ? 'activeCardBg' : ''}>
                                                            <Typography gutterBottom sx={{ color: "#fff", fontSize: 12, textAlign: 'center' }}>
                                                                {item?.name}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            )
                                        })
                                    }

                                </Grid>
                                <IconButton
                                    onClick={() => refreshInights()}
                                    size="large"
                                    aria-label="like"
                                    style={{ padding: 0 }}
                                    disabled={defaultData.length === 0 ? true : false}
                                >
                                    <RefreshIcon fontSize='large' color='primary' style={{width: '0.9em', height:'0.9em'}} />
                                </IconButton>

                            </div>
                            <div className='chatsection'>
                                <Prompter
                                    tab={tab}
                                    email={email}
                                    defaultData={defaultData}
                                    setDefaultData={setDefaultData}
                                    selectedInsight={selectedInsight}
                                    showLoader={showLoader}
                                    setShowLoader={setShowLoader}
                                    fetchChatInsights={(data: any) => fetchChatInsights(data)}
                                    handleDownloadPdf={handleDownloadPdf}
                                    componentRef={componentRef}
                                />
                            </div>
                        </div>
                    </Box>
                    {/* Filter Section */}
                    {right && (
                        <Box
                            className={right ? 'filter-expanded' : 'filter-hidden'}
                            sx={{ width: '25%', transition: 'width 0.3s', p: 0 }}
                        >
                            <div className='arrowSwap arrowBack'>
                                <ChevronRightIcon onClick={() => setRight(false)} style={{ cursor: 'pointer' }} />
                            </div>
                            <div className='rightFilters'>
                                <div className='filterdiv'>
                                    <div className='width_filter clscolordot padingLR'>
                                        <div className='lableTitle'>Select Machine Model</div>
                                        <div className='dropboxbttn'>
                                            <DropDown
                                                onChange={(e: any) => {
                                                    setSelectedMachineModel(e.target.value);
                                                    fetchMachines(e.target.value);
                                                }}
                                                className="inputDiv"
                                                name="dropdownAction"
                                                items={machineModel}
                                                placeHolder="Select Machine Model"
                                                value={selectedMachineModel}
                                            />
                                        </div>
                                    </div>

                                    <div className='width_filter clscolordot padingLR'>
                                        <div className='lableTitle'>Select Machine Serial Number</div>
                                        <div className='multiboxcheck'>
                                            <FormControl sx={{ width: '100%' }}>
                                                {selectedMachines?.length > 0 ? null : (
                                                    <InputLabel
                                                        style={{
                                                            fontFamily: 'Montserrat',
                                                            fontSize: '12px',
                                                            paddingTop: '0px',
                                                            paddingLeft: '12px',
                                                            color: '#b1c2df',
                                                            lineHeight: '1',
                                                            opacity: '0.7'
                                                        }}
                                                        shrink={false}
                                                        id="demo-multiple-checkbox-label"
                                                    >Select Machine Serial Number
                                                    </InputLabel>)}
                                                <Select
                                                    IconComponent={DropDownIcon}
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    className='chekboxmulti'
                                                    multiple
                                                    disabled={props.selectedMachine === "" ? true : false}
                                                    value={selectedMachines}
                                                    sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                                                    placeholder='Please select machines'
                                                    renderValue={(selected: any) => {
                                                        const filtered = machines.filter((opr: any) =>
                                                            selected.some((item: any) => item === opr.code)
                                                        );
                                                        return filtered.map((x: any) => x.code).join(', ')
                                                    }
                                                    }
                                                    MenuProps={MenuProps}

                                                >
                                                    <MenuItem value={"all"} onClick={() => handleSelectAll()} className='ulSelectProduct'>
                                                        <Checkbox
                                                            checked={selectedMachines.length === machines.length}

                                                        />
                                                        <ListItemText primary="Select All" />
                                                    </MenuItem>
                                                    {machines?.map((i: any) => {
                                                        return (
                                                            <MenuItem value={i} onClick={() => handleCheckboxChange(i.code)} className='ulSelectProduct'>
                                                                <Checkbox
                                                                    checked={selectedMachines.includes(i.code)}
                                                                />
                                                                <ListItemText primary={i.name} />
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className='width_filter clscolordot padingLR'>
                                        <div className='lableTitle'>From</div>
                                        <CustomDatePicker
                                            name="dateFromSelect"
                                            value={inDate}
                                            maxDate={new Date()}
                                            className="inputDiv"
                                            onChange={(val: any) => {
                                                setInDate(val);
                                                window.newrelic.addPageAction('OEE-insights', { action: 'From date changed', additionalInfo: `From Date changed to ${val}` });
                                            }}
                                            inputFormat="dd/MM/yyyy"
                                            placeholder="Select From Date"
                                            minDate={new Date("01-01-2023")}

                                        />
                                    </div>
                                    <div className='width_filter clscolordot padingLR'>
                                        <div className='lableTitle'>To</div>
                                        <CustomDatePicker
                                            name="dateFromSelect"
                                            value={endDate}
                                            minDate={inDate}
                                            maxDate={new Date()}
                                            className="inputDiv"
                                            onChange={(val: any) => {
                                                setEndDate(val);
                                                window.newrelic.addPageAction('OEE-Insights', { action: 'To date changed', additionalInfo: `To Date changed to ${val}` });
                                            }}
                                            inputFormat="dd/MM/yyyy"
                                            placeholder="Select End Date"
                                        />
                                    </div>
                                    <div className={'btns noWrap'}>

                                        <ACGButton
                                            name={'Submit'}
                                            handleClick={() => { applyFilterHandler() }}
                                            type="submit"
                                            className="second-btn"
                                            variant="contained"
                                        />
                                        <div style={{ marginTop: 8, display: "inline-flex", alignItems: "center" }}>
                                            <InfoIcon fontSize='small' style={{ color: '#ffff', width: '0.8rem', height: '0.8rem', marginRight: 4 }} /> <Typography
                                                style={{ color: "#ffff", fontSize: 9 }}>
                                                Applying filters will erase the previous chat history
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    )}
                </Box>
            </Container>
            <AlertBox
                type="filter"
                isAlertImg={false}
                isOpen={removeBxOpen}
                handleClose={handleDialogClose}
                leftBtnPress={() => { setRemoveBxOpen(false); setDefaultData([]); setCanDownload(false); setApplyFilter(true) }}
                rightBtnPress={async () => {
                    setCanDownload(true);
                    setRemoveBxOpen(false);
                    await rightBtnPress();
                   
                    fetchDefaultInsights(selectedInsight, selectedMachines);
                }}
                modalHeader="Apply Filters"
                modalContent="Do you want to download the previous chat history?"
            />
        </div>
    );
};

export default OEEInsights;