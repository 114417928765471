import { Box, TextField, IconButton } from '@mui/material';
import MessageBubble from './messageBubble';
import SendIcon from '@mui/icons-material/Send';
import React, { useState, useRef, useEffect } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { PropagateLoader } from 'react-spinners';

const Prompter = (props: any) => {
  const { componentRef, tab, email, defaultData, setDefaultData, selectedInsight, showLoader, setShowLoader, fetchChatInsights, handleDownloadPdf } = props;

  const [input, setInput] = useState('');
  const messagesEndRef = useRef<any>(null);

  const handleSend = async () => {
    if (input) {
      const index = defaultData.findIndex((item: any) => item.hasOwnProperty(selectedInsight));
      if (index !== -1) {
        const updatedData: any = [...defaultData];
        const oldData: any = Object.values(updatedData[index])[0];
        oldData.push({ text: input, isUser: true });
        updatedData[index] = {
          ...updatedData[index],
          [selectedInsight]: oldData
        };

        const newData: any = Object.values(updatedData[index])[0];
        const newResponse = await fetchChatInsights(input);
        newData.push(newResponse);
        updatedData[index] = {
          ...updatedData[index],
          [selectedInsight]: newData
        };
        setDefaultData([...updatedData]);
      }
      setInput('');
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [defaultData, showLoader]);

  const filteredAIData = defaultData?.filter((item: any) => item.hasOwnProperty(selectedInsight));

  return (
    <Box display="flex" flexDirection="column" style={{ background: '#24263a', height: "calc(100vh - 212px)", width: "100%" }}>
      <Box ref={componentRef} flex="1" overflow="auto" p={2}>
        {showLoader ? (
          <>
            {filteredAIData?.map((msg: any, index: number) =>
              msg[selectedInsight]?.map((insight: any, i: number) => (
                <MessageBubble
                  key={`${index}-${i}`}
                  tab={tab}
                  message={insight.text}
                  isUser={insight.isUser}
                  showLoader={showLoader}
                  lastItem={index === msg[selectedInsight].length - 1}
                  email={email}
                  itemIndex={i}
                  data={defaultData}
                  selectedInsight={selectedInsight}
                  setDefaultData={setDefaultData}
                  feedback={insight.feedback}
                  images={insight.images}
                />
              ))
            )}
            <PropagateLoader color="#5991ec" />
          </>
        ) : (
          filteredAIData?.map((msg: any, index: number) =>
            msg[selectedInsight]?.map((insight: any, i: number) => (
              <MessageBubble
                key={`${index}-${i}`}
                tab={tab}
                message={insight.text}
                isUser={insight.isUser}
                showLoader={showLoader}
                lastItem={index === msg[selectedInsight].length - 1}
                email={email}
                itemIndex={i}
                data={defaultData}
                selectedInsight={selectedInsight}
                setDefaultData={setDefaultData}
                feedback={insight.feedback}
                images={insight.images}
              />
            ))
          )
        )}
        <div ref={messagesEndRef} />
      </Box>
      <Box p={1} display="flex" alignItems="center" borderTop="1px solid #b1c2df">
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Ask a question to get insights from your data!"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className='imputboxx'
          disabled={showLoader}
          sx={{
            '& .MuiInputBase-input': {
              color: '#e0e0e0',
              background: 'transparent',
            },
            '& .MuiFormLabel-root': {
              color: '#e0e0e0',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#b1c2df',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
              borderWidth: '0px !important',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
              borderWidth: '0px',
            },
            '& .MuiInputBase-root': {
              borderRadius: '24px',
              fontSize: '12px',
              height: '40px',
              backgroundColor: '#2b2d42',
              border:'1px solid #b1c2df',
              marginLeft: '10px',
              width: 'calc(100% - 20px)',
            },
            '& .MuiInputBase-input::placeholder': {
              color: '#425664', 
              opacity: 1,     
            },
            '&.Mui-disabled': {
    color: '#9e9e9e !important',
    background: 'rgba(0, 0, 0, 0.1)',
    cursor: 'not-allowed',
            }
          }}
        />
        <IconButton color="primary" style={{ color: '#5991ec' }} disabled={showLoader} onClick={handleSend}>
          <SendIcon />
        </IconButton>
        <IconButton color="primary" title="Download Chat" onClick={handleDownloadPdf}>
          <DownloadIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Prompter;
