import { SetStateAction, useEffect, useRef, useState } from 'react';
import {
    Checkbox,
    Container,
    FormControl,
    ListItemText,
    MenuItem,
    Grid,
    Select,
    Typography,
    Box
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Attachments from '../../../../atoms/ExcelAttachments';
import DownArrow from '../../../../../assets/down-arrow.svg';
import Edit from '../../../../../assets/images/edit.svg';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import dateFormat from 'dateformat';
import { SnackBarOptions, Dateformat } from '../../../UserReport/DownloadableReport/CommonUtil';
import { Formik, Form, FormikProps, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { MD_YUPSCHEMA } from './schema';
import useGetState from '../../../../utils/hooks/useGetState';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import ACGButton from '../../../../atoms/Button';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import DropDown from '../../../../atoms/Dropdown';
import { fetchBlobImage } from '../../../../utils/helper';
import { convertBlobFileToBase64 } from '../../../../utils/helper';
import Axios from 'axios';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import { assign } from 'util';
import { kMaxLength } from 'buffer';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import * as XLSX from 'xlsx';
import { trackErrorsInNewRelic } from '../../../../utils/newRelicFuncations';

type ComponentProps = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    dtlData: any;
    selectedRow: any;
    isEdit: boolean;
    setDtlData: Function;
    setSelectedRow: Function;
    assetList: any;
    categoryList: any;
    actionTypeList: any;
    preventiveMaintenanceKey: any;
    getPreventiveMaintenanceList: Function;
    fetchPreventiveMaintenanceOrderDetails: Function;
    actionTypePM: any;
    assignmentData?: any;
    drawerHeader?: any;
};
type ModuleType = {
    startDate: any;
    endDate: any;
    scName: string | '';
    scValue: string | '';
    scDesc: string | '';
};

const CreateModule = (props: ComponentProps) => {
    const { preventiveMaintenanceKey, drawerClose, setCurrTab, setReload = () => { }, setSnackbarOpts = () => { }, dtlData, isEdit, setDtlData = () => { }, selectedRow, setSelectedRow = () => { }, assetList, categoryList, actionTypeList, getPreventiveMaintenanceList = () => { }, fetchPreventiveMaintenanceOrderDetails = () => { }, actionTypePM, assignmentData, drawerHeader } = props;
    const formRef = useRef<any>(null);
    const [machineKey, setMachineKey] = useState('');
    const [preCategoryList, setPreCategoryList] = useState<any[]>([]);
    const [preCategoryKey, setPreCategoryKey] = useState<string | number>('');
    const [actionTypeKey, setActionTypeKey] = useState<string | number>('');
    const [machineDetails, setMachineDetails] = useState<any[]>([]);
    const [componentList, setComponentList] = useState<any[]>([]);
    const [subComponentList, setSubComponentList] = useState<any[]>([]);
    const [componentKey, setComponentKey] = useState('');
    const [subComponentKey, setSubComponentKey] = useState('');
    const [maintenanceRequired, setMaintenanceRequired] = useState('');
    const [sparePartsList, setSparepartsList] = useState<any[]>([]);
    const [partsNames, setPartsNames] = useState<any[]>([]);
    const [changedTD, setChangedTD] = useState(false);
    const [changedETD, setChangedETD] = useState(false);
    const [assignType, setAssignType] = useState("R")
    const [periodList, setPeriodList] = useState<any[]>([]);
    const [periodKey, setPeriodKey] = useState<any>('');
    const [occurances, setOccurances] = useState<any>('');
    const [selectedModelImage, setSelectedModelImage] = useState<any>([]);
    const [formError, setFormError] = useState({ modelCode: '', modelName: '', paramsUpload: '', modelImage: '', hasError: false });
    const [validationSchema, setValidationSchema] = useState({});
    const [initialValues, setInitialValues] = useState<any>({
        startDate: null,
        endDate: null,
        scName: '',
        scValue: '',
        scDesc: '',
    });
    const [editImage, setEditImage] = useState(false);
    const [fName, setFname] = useState('');
    const [templateUploadType, setTemplateUploadType] = useState<any>(null)
    const [selectedTemplate, setSelectedTemplate] = useState<any>([]);
    const [templateError, setTemplateError] = useState<boolean>(false);
    const [isSetFile, setIsSetFile] = useState<any>([]);
    const [templateSuccess, setTemplateSuccess] = useState(false);
    const [sheetData, setSheetData] = useState<any>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [ExcelSubmitResponse, setExcelSubmitResponse] = useState<any[]>([]);
    const [disableCon, setDisableCon] = useState({
        component: "Y",
        subComponent: "Y",
        mainDesc: "Y",
        actionType: "Y",
        category: "Y",
        endDate: "Y",
        endType: "Y",
        occurance: "Y",
        period: "Y",
        startDate: "Y",
    })

    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const dispatch = useDispatch();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    const options = {
        AD_SUCCESS: {
            message: `Preventive Maintenance ${preventiveMaintenanceKey === "" ? 'created' : 'edited'} successfully!`,
            open: true,
            type: 'success'
        },
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        setValidationSchema(Yup.object().shape(MD_YUPSCHEMA));
        getCategory();
        getPeriod();
    }, []);

    useEffect(() => {
        if (preventiveMaintenanceKey !== '' && assignmentData.length > 0  ) {
            const tmpTableData = assignmentData;
            if (tmpTableData && tmpTableData.length) {
                setMachineKey(tmpTableData[0].MachineKey);
                getMachineDetails(state[STORE_KEYS.USER_DTL]?.LoggedUserKey, tmpTableData[0].MachineKey);
                setComponentKey(tmpTableData[0].MaintComponentKey);
                getSubComponents(tmpTableData[0].MaintComponentKey);
                setSubComponentKey(tmpTableData[0].MaintSubComponentKey);
                setMaintenanceRequired(tmpTableData[0].MaintDesc);
                setActionTypeKey(tmpTableData[0].MaintActionTypeKey);
                setPreCategoryKey(tmpTableData[0].MaintPreventiveCategoryKey);
                setPeriodKey(tmpTableData[0]?.MaintPreventivePeriodKey);
                tmpTableData[0]?.MaintPreventiveEndDate === null ? setAssignType("R") : setAssignType("RD");
                setOccurances(tmpTableData[0]?.MaintPreventiveNoOfOccurrance);
                setInitialValues({
                    ...initialValues,
                    startDate: new Date(tmpTableData[0].MaintPreventiveStartDate),
                    endDate:tmpTableData[0]?.MaintPreventiveEndDate? new Date(tmpTableData[0]?.MaintPreventiveEndDate):new Date()
                });
                setDisableCon({
                    component: tmpTableData[0].IsEnableEditComponent,
                    subComponent: tmpTableData[0].IsEnableEditSubComponent,
                    actionType: tmpTableData[0].IsEnableEditMaintActionType,
                    mainDesc: tmpTableData[0].IsEnableEditMaintDesc,
                    category: tmpTableData[0].IsEnableEditMaintPreventiveCategory,
                    endDate: tmpTableData[0].IsEnableEditMaintPreventiveEndDate,
                    endType: tmpTableData[0].IsEnableEditMaintPreventiveEndType,
                    occurance: tmpTableData[0].IsEnableEditMaintPreventiveNoOfOccurrance,
                    period: tmpTableData[0].IsEnableEditMaintPreventivePeriod,
                    startDate: tmpTableData[0].IsEnableEditMaintPreventiveStartDate,
                })
            setTemplateUploadType("formUpload")
        }
    }
    }, [assignmentData]);

    const handleTemplateRadio = (event: any) => {
        setTemplateUploadType(event.target.value)
        setExcelSubmitResponse([])
    }

    const getCategory = async () => {
        let finalResult;
        const payload = {
            userId: loggedInUser,
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_PREVENTIVE_MAINT_CATEGORY,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setPreCategoryList([
                        ...tmpTableData?.map((item: any) => ({ code: item.MaintPreventiveCategoryKey, name: item.MaintPreventiveCategoryName }))

                    ]);
                    finalResult = [...tmpTableData?.map((item: any) => ({ code: item.MaintPreventiveCategoryKey, name: item.MaintPreventiveCategoryName }))]
                }
            })
            .catch((error) => {
             trackErrorsInNewRelic({error: error})
                return {
                    status: error.response.status
                };
            });
        return finalResult;
    }

    const getPeriod = async () => {
        let finalResult;
        const payload = {
            userId: loggedInUser,
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_PREVENTIVE_MAINT_PERIOD,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setPeriodList([
                        ...tmpTableData?.map((item: any) => ({ code: item.MaintPreventivePeriodKey, name: item.MaintPreventivePeriodName }))

                    ]);
                    finalResult = [...tmpTableData?.map((item: any) => ({ code: item.MaintPreventivePeriodKey, name: item.MaintPreventivePeriodName }))]
                }
            })
            .catch((error) => {
             trackErrorsInNewRelic({error: error})
                return {
                    status: error.response.status
                };
            });
        return finalResult;
    }

    let checkCategory: boolean, dateCheck = false, occurCheck = false;
    if (preCategoryKey === 2) {
        if (periodKey === '') {
            checkCategory = true;
        } else checkCategory = false;
    } else checkCategory = false;

    if (periodKey !== "") {
        if (assignType === "RD" && preCategoryKey === 2) {
            if (initialValues.endDate < initialValues.startDate) {
                dateCheck = true;
            } else dateCheck = false;
        }
        if (assignType === "R" && preCategoryKey === 2) {
            if (occurances === '') {
                ;
                occurCheck = true
            } else occurCheck = false;
        } else occurCheck = false;
    }

    const isDisabled = sheetData.length ? false : (initialValues.startDate === null || checkCategory || dateCheck || occurCheck || machineKey === '' || preCategoryKey === '' || componentKey === '' || subComponentKey === '' || maintenanceRequired === '' || !/\S/.test(maintenanceRequired));

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                {!ExcelSubmitResponse.length && <ACGButton
                    name={'Submit'}
                    handleClick={() => { templateUploadType === 'formUpload' ? handleSubmit() : handleExcelSubmit() }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={isDisabled ? true : false}
                />}

            </div>
        );
    };

    const handleSubmit = async () => {
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.ADD_PREVENTIVE_MAINTENANCE,
                requestType: 'POST',
                reqObj: {
                    userId: loggedInUser,
                    machineKey: machineKey,
                    subComponentKey: subComponentKey,
                    maintenanceRequired: maintenanceRequired,
                    categoryKey: preCategoryKey,
                    actionType: actionTypeKey,
                    startDate: dateFormat(initialValues.startDate, Dateformat.YYYY_MM_DD),
                    preventiveEndType: preCategoryKey === 1 ? "ONETIME" : (assignType === "R" && preCategoryKey !== 1) ? "NOOFOCCUR" : "ENDDATE",
                    periodKey: preCategoryKey === 2 ? periodKey : null,
                    occurances: preCategoryKey === 2 && assignType === "R" ? occurances : null,
                    endDate: preCategoryKey === 2 && assignType === "RD" ? dateFormat(initialValues.endDate, Dateformat.YYYY_MM_DD) : null,
                    key: preventiveMaintenanceKey !== '' ? preventiveMaintenanceKey : null,
                    type: preventiveMaintenanceKey !== '' ? 'Edit' : 'Add',

                }
            },
            uniqueScreenIdentifier: { preventiveMaintmoduleAdded: true },
            storeKey: STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE
        };
        dispatch(executeACGAction(bundle));
    };
    const handleExcelSubmit = async () => {
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.ADD_PREVENTIVE_MAINTENANCE_EXCEL,
                requestType: 'POST',
                reqObj: {
                    userId: loggedInUser,
                    machineKey: machineKey,
                    type: preventiveMaintenanceKey !== '' ? 'Edit' : 'Add',
                    PMSchJson: sheetData
                }
            },
            uniqueScreenIdentifier: { preventiveMaintmoduleAdded: true },
            storeKey: STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE_EXCEL
        };
        dispatch(executeACGAction(bundle));
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE]?.preventiveMaintmoduleAdded) {
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE,
                    uniqueScreenIdentifier: {
                        preventiveMaintmoduleAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE]?.data]);
    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE_EXCEL]?.preventiveMaintmoduleAdded) {
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE_EXCEL,
                    uniqueScreenIdentifier: {
                        preventiveMaintmoduleAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE_EXCEL]?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE]?.preventiveMaintmoduleAdded) {
            if (state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE]?.data[0].Result === "Success") {

                const errorMsg = state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE]?.data[0].ResultMessage || 'Internal Server error';
                const snackbarError = {
                    message: errorMsg,
                    type: 'success',
                    open: true
                };
                setSnackbarOpts(snackbarError);
                setReload(true);
                setCurrTab(0);
                drawerClose();
                getPreventiveMaintenanceList();
                preventiveMaintenanceKey !== '' ? fetchPreventiveMaintenanceOrderDetails(preventiveMaintenanceKey) : undefined;
            } else if (state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE]?.data[0].Result === "Error") {
                const errorMsg = state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE]?.data[0].ResultMessage || 'Internal Server error';
                const snackbarError = {
                    message: errorMsg,
                    type: 'reject',
                    open: true
                };
                setSnackbarOpts(snackbarError);
                setCurrTab(0);
                drawerClose();
                preventiveMaintenanceKey !== '' ? fetchPreventiveMaintenanceOrderDetails(preventiveMaintenanceKey) : undefined;

            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE,
                    uniqueScreenIdentifier: {
                        preventiveMaintmoduleAdded: false
                    }
                })
            );
        }
        if (state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE_EXCEL]?.preventiveMaintmoduleAdded) {
            if (state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE_EXCEL]?.status === "Success") {
                setExcelSubmitResponse(state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE_EXCEL].data)
                getPreventiveMaintenanceList();
                // setReload(true);
                // setCurrTab(0);
                // setSnackbarOpts(options.AD_SUCCESS);
                // drawerClose();
                // preventiveMaintenanceKey !== '' ? fetchPreventiveMaintenanceOrderDetails(preventiveMaintenanceKey) : undefined;
                // getPreventiveMaintenanceList();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE_EXCEL,
                    uniqueScreenIdentifier: {
                        preventiveMaintmoduleAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE]?.preventiveMaintmoduleAdded, state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE_EXCEL]?.preventiveMaintmoduleAdded]);

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const getMachineDetails = (userId: any, mKey: any) => {

        const payload = {
            userId: loggedInUser,
            machineKey: mKey
        };
        Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_BREAKDOWN_MACHINE_DETAILS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    setMachineDetails(response.data.data);
                    getComponent(response.data.data[0].MachineModelKey);
                }
            })
            .catch((error) => {
             trackErrorsInNewRelic({error: error})

                return {
                    status: error.response.status
                };
            });

    }

    const handleChangeAsset = (event: { target: { value: any; }; }) => {
        setMachineKey(event.target.value);
        getMachineDetails(state[STORE_KEYS.USER_DTL]?.LoggedUserKey, event.target.value);
    }

    const getComponent = (mmKey: Number) => {
        const payload = {
            userId: loggedInUser,
            machineModelKey: mmKey
        };
        Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_BREAKDOWN_COMPONENTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setComponentList([
                        ...tmpTableData?.map((item: any) => ({ code: item.MaintComponentKey, name: item.MaintComponentName }))
                    ]);
                }
            })
            .catch((error) => {
               trackErrorsInNewRelic({error: error})

                return {
                    status: error.response.status
                };
            });
    }

    const getSubComponents = (cKey: number) => {
        const payload = {
            userId: loggedInUser,
            componentKey: cKey
        };
        Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_BREAKDOWN_SUB_COMPONENTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setSubComponentList([
                        ...tmpTableData?.map((item: any) => ({ code: item.MaintSubComponentKey, name: item.MaintSubComponentName }))
                    ]);
                }
            })
            .catch((error) => {
                 trackErrorsInNewRelic({error: error})

                return {
                    status: error.response.status
                };
            });
    }

    const handleChangeComponent = (event: { target: { value: any; }; }) => {
        setSubComponentKey('');
        setComponentKey(event.target.value);
        getSubComponents(event.target.value);
    }

    const handleChangeSubComponent = (event: { target: { value: any; }; }) => {
        setSparepartsList([]);
        setPartsNames([]);
        setSubComponentKey(event.target.value);
    }

    const handleChangePreCategory = (event: { target: { value: any; }; }) => {
        setPreCategoryKey(event.target.value)
    }

    const handleChangeActionType = (event: { target: { value: any; }; }) => {
        setActionTypeKey(event.target.value)
    }

    const handleChangePeriodType = (event: { target: { value: any; }; }) => {
        setPeriodKey(event.target.value);
    }

    const handleChangeAssignType = (event: { target: { value: SetStateAction<string>; }; }) => {
        setAssignType(event.target.value);
    };

    const DropDownIcon = (prop: any) => (
        <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
    );

    const uploadFile = (f: any) => {
        // setSheetResult([]);
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
            reader.onload = (e) => {
                processExcel(reader.result);
            };
            reader.readAsBinaryString(f[0]);
        }
    }

    function processExcel(data: any) {
        const allRows: any = [];
        let isError: boolean = false;
        const workbook = XLSX.read(data, { type: 'binary' });
        const firstSheet = workbook.SheetNames[0];
        const excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
        excelRows.map(async (item: any) => {
            if (Object.keys(item).length !== 5) {
                isError = true;
                return
            } else {
                const data = Object.values(item)
                const obj = {
                    MaintSubComponentName: data[0],
                    MaintDesc: data[1],
                    MaintActionTypeName: data[2],
                    MaintPreventivePeriodName: data[3],
                    MaintPreventiveNoOfOccurrance: data[4]
                }
                await allRows.push(obj);
            }
        })
        if (isError) {
            setTemplateError(true);
            setSelectedTemplate([]);
        } else {
            setTemplateError(false);
            setSelectedTemplate([]);
            setTemplateSuccess(true);
            setSheetData(allRows);
        }
    }

    return (
        <Container className="createReport">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values: any) => {
                    // handleSubmit(values);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: FormikProps<ModuleType>) => {
                    const { values, handleChange, handleBlur, errors, touched, setFieldValue } = formikprops;
                    return (
                        <Form>
                            <Label label="Asset ID &nbsp; *" className={'labelDiv'} />
                            <DropDown
                                onChange={handleChangeAsset}
                                className="inputDiv"
                                name="dropdownModel"
                                items={assetList}
                                placeHolder="Select Asset ID"
                                value={machineKey}
                                disabled={preventiveMaintenanceKey !== '' && machineKey !== '' ? true : false}
                            // helperText={
                            //     errors.dropdownModel && touched.dropdownModel ? errors.dropdownModel : ''
                            // }
                            />
                            {machineKey !== '' ?
                                <>
                                    <Label label="Asset Details" className={'labelDiv'} />
                                    <img
                                        style={{
                                            padding: 10, maxHeight: "200px",
                                            objectFit: "contain",
                                            width: "100%"
                                        }}
                                        src={
                                            machineDetails[0]?.MachineModelName ? fetchBlobImage(machineDetails[0]?.MachineModelName + ".png").url : ""
                                        } alt="No image" />
                                    <div className='bordernone'>
                                        <TextInput
                                            variant="outlined"
                                            autoFocus
                                            fullWidth
                                            type="text"
                                            name="scName"
                                            disabled={true}
                                            value={"Model: " + machineDetails[0]?.MachineModelName}
                                            autoComplete="off"
                                            formInput="inputDiv"
                                            maxLength={300}
                                        />
                                        <TextInput
                                            variant="outlined"
                                            autoFocus
                                            fullWidth
                                            type="text"
                                            name="scName"
                                            disabled={true}
                                            value={"Year of purchase: " + machineDetails[0]?.YearOfPurchase}
                                            autoComplete="off"
                                            formInput="inputDiv"
                                        />
                                        <TextInput
                                            variant="outlined"
                                            autoFocus
                                            fullWidth
                                            type="text"
                                            name="scName"
                                            disabled={true}
                                            value={"Warranty info: " + machineDetails[0]?.WarrantyInfo}
                                            autoComplete="off"
                                            formInput="inputDiv"
                                        />
                                        <TextInput
                                            variant="outlined"
                                            autoFocus
                                            fullWidth
                                            type="text"
                                            name="scName"
                                            disabled={true}
                                            value={"Current AMC status: " + machineDetails[0]?.CurrentAMCStatus}
                                            autoComplete="off"
                                            formInput="inputDiv"
                                        />
                                    </div>
                                    {preventiveMaintenanceKey == '' ? <><Label label="Select Template Upload Option" className={'labelDiv'} />
                                        <div className='mainradiocls'>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="formUpload"
                                                    name='options'
                                                    checked={templateUploadType === 'formUpload'}
                                                    onChange={handleTemplateRadio}
                                                />
                                                Enter PM Schedule
                                            </label>
                                            <label style={{ marginLeft: "20px" }}>
                                                <input
                                                    type="radio"
                                                    value="fileUpload"
                                                    name='options'
                                                    checked={templateUploadType === 'fileUpload'}
                                                    onChange={handleTemplateRadio}
                                                />
                                                Upload PM Schedule
                                            </label>
                                        </div></> : null}

                                    {templateUploadType == "formUpload" ? <><Label label="Select Component &nbsp; *" className={'labelDiv'} />
                                        <DropDown
                                            onChange={handleChangeComponent}
                                            className="inputDiv"
                                            name="dropdownModel"
                                            items={componentList}
                                            placeHolder="Select Component"
                                            value={componentKey}
                                            disabled={disableCon.component == "N"}
                                        />
                                        <Label label="Select Sub Component &nbsp; *" className={'labelDiv'} />
                                        <DropDown
                                            onChange={handleChangeSubComponent}
                                            className="inputDiv"
                                            name="dropdownModel"
                                            items={subComponentList}
                                            placeHolder="Select Sub Component"
                                            value={subComponentKey}
                                            disabled={disableCon.subComponent == "N"}
                                        />
                                        <Label label="Maintenance Description &nbsp; *" className={'labelDiv'} />
                                        <TextInput
                                            variant="outlined"
                                            fullWidth
                                            type="text"
                                            name="gtId"
                                            value={maintenanceRequired}
                                            autoComplete="off"
                                            formInput="inputDiv"
                                            placeholder='Maintenance Description'
                                            onChange={(e: any) => {
                                                setMaintenanceRequired(e.target.value)
                                            }}
                                            maxLength={300}
                                            disabled={disableCon.mainDesc == "N"}
                                        />
                                        <Label label="Action Type &nbsp; *" className={'labelDiv'} />
                                        <DropDown
                                            onChange={handleChangeActionType}
                                            className="inputDiv"
                                            name="dropdownModel"
                                            items={actionTypePM}
                                            placeHolder="Action Type"
                                            value={actionTypeKey}
                                            disabled={disableCon.actionType == "N"}
                                        />
                                       
                                        <Label label="Select Category &nbsp; *" className={'labelDiv'} />
                                        <DropDown
                                            onChange={handleChangePreCategory}
                                            className="inputDiv"
                                            name="dropdownModel"
                                            items={preCategoryList}
                                            placeHolder="Select Category"
                                            value={preCategoryKey}
                                            disabled={disableCon.category == "N"}
                                        />
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                                <Label label="Select Date &nbsp; *" className={'labelDiv'} />
                                                <CustomDatePicker
                                                    name="startDate"
                                                    value={preventiveMaintenanceKey !== '' && !changedTD ? initialValues.startDate : values.startDate}
                                                    className="inputDiv"
                                                    onChange={(val: any) => {
                                                        setFieldValue('startDate', val);
                                                        setInitialValues({ ...initialValues, startDate: val });
                                                        setChangedTD(true)
                                                    }}
                                                    inputFormat="dd/MM/yyyy"
                                                    placeholder="Select Start Date"
                                                    minDate={new Date()}
                                                    disabled={disableCon.startDate == "N"}
                                                />
                                            </Grid>
                                        </Grid>

                                        {preCategoryKey === 2 ?
                                            <>
                                                <Label label="Period &nbsp; *" className={'labelDiv'} />
                                                <DropDown
                                                    onChange={handleChangePeriodType}
                                                    className="inputDiv"
                                                    name="dropdownModel"
                                                    items={periodList}
                                                    placeHolder="Select Period"
                                                    value={periodKey}
                                                    disabled={disableCon.period == "N"}
                                                />
                                                <Label label="Please Choose &nbsp; *" className={'labelDiv'} />
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group" className='radiolabls'

                                                >
                                                    <FormControlLabel style={{ color: '#a0a2b8' }} value="R" control={<Radio disabled={disableCon.endType == "N"} onChange={handleChangeAssignType} checked={assignType === "R" ? true : false} />} label="Recurrence by count" />
                                                    <FormControlLabel style={{ color: '#a0a2b8' }} value="RD" control={<Radio disabled={disableCon.endType == "N"} onChange={handleChangeAssignType} checked={assignType === "RD" ? true : false} />} label="Recurrence till end date" />

                                                </RadioGroup>
                                            </>
                                            : null}
                                        {
                                            preCategoryKey === 2 ?
                                                assignType === "RD" ?
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                                            <Label label="Select End Date &nbsp; *" className={'labelDiv'} />
                                                            <CustomDatePicker
                                                                name="startDate"
                                                                value={preventiveMaintenanceKey !== '' && !changedTD ? initialValues.endDate : values.endDate}
                                                                className="inputDiv"
                                                                onChange={(val: any) => {
                                                                    setFieldValue('endDate', val);
                                                                    setInitialValues({ ...initialValues, endDate: val });
                                                                    setChangedETD(true)
                                                                }}
                                                                inputFormat="dd/MM/yyyy"
                                                                placeholder="Select End Date"
                                                                minDate={initialValues.startDate}
                                                                disabled={disableCon.endDate == "N"}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    <>
                                                        <Label label="Number Of Occurences &nbsp; *" className={'labelDiv'} />
                                                        <TextInput
                                                            variant="outlined"
                                                            fullWidth
                                                            name="gtId"
                                                            value={occurances}
                                                            autoComplete="off"
                                                            formInput="inputDiv"
                                                            placeholder='Number Of Occurences'
                                                            onChange={(e: any) => {
                                                                const value = e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                                                setOccurances(value.replace(/\D/g, ""));
                                                            }}
                                                            maxLength={2}
                                                            InputProps={{
                                                                inputProps: {
                                                                    min: 0, maxLength: 2, max: 2, inputMode: "numeric",
                                                                    pattern: "[0-9]*"
                                                                }
                                                            }}
                                                            disabled={disableCon.occurance == "N"}
                                                        />
                                                    </>
                                                : null
                                        }
                                        <Typography variant="body1" sx={{ color: "#ffff", marginTop: 2 }}>Note: * fields are mandatory! </Typography></>
                                        : templateUploadType == "fileUpload" ?
                                            <div>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div>
                                                        <Label label="Download Template" className={'CMlabelDiv'} />
                                                        <Button sx={{ width: "100px" }} >
                                                            <a href={"https://stbtffuturednadlsqa.blob.core.windows.net/smartconnected-files/Template_PM_Schedule.xlsx?sp=racwle&st=2024-07-09T13:07:26Z&se=2025-07-08T21:07:26Z&spr=https&sv=2022-11-02&sr=c&sig=9xfDXquPJRY5MEOIawH6IuvF%2BMYHGivOGHpMn4xYZyo%3D"} download >
                                                                <DownloadIcon style={{ width: "30px", height: "30px", color: "#ffffff" }} />
                                                            </a>
                                                        </Button>
                                                    </div>
                                                    <div style={{ marginLeft: "100px" }}><Label label="Upload Template" className={'CMlabelDiv'} />
                                                        <Attachments
                                                            viewOnly={false}
                                                            setSelectedTemplate={setSelectedTemplate}
                                                            isSetFile={isSetFile}
                                                            uploadFile={uploadFile}
                                                            type={['.xlsx', '.xls']}
                                                            templateError={templateError}
                                                            setTemplateError={setTemplateError}
                                                            selectedTemplate={selectedTemplate}
                                                            templateSuccess={templateSuccess}
                                                        />

                                                    </div>
                                                </div>
                                                {ExcelSubmitResponse.length ? <Grid item md={12} className='tableuploaddata' style={{ margin: "10px 0" }}>
                                                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                                        <TableContainer sx={{ maxHeight: 440 }}>
                                                            <Table stickyHeader aria-label="sticky table" className='tableColumn clcTh'>
                                                                <TableHead >
                                                                    <TableRow>
                                                                        <TableCell>Sub Component</TableCell>
                                                                        <TableCell>Maintenance Description</TableCell>
                                                                        <TableCell>Action Type</TableCell>
                                                                        <TableCell>Period</TableCell>
                                                                        <TableCell>Number of Occurrences</TableCell>
                                                                        <TableCell>Uploaded Status</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {ExcelSubmitResponse.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                                                        <TableRow hover key={index} className='tableRow clcTd'>
                                                                            <TableCell component="th" scope="row">
                                                                                {row.SubComponent}
                                                                            </TableCell>
                                                                            <TableCell>{row.MaintenanceDescription}</TableCell>
                                                                            <TableCell>{row.ActionType}</TableCell>
                                                                            <TableCell>{row.Period}</TableCell>
                                                                            <TableCell>{row.Numberofoccurrences}</TableCell>
                                                                            <TableCell style={{ color: row.Result == "Success" ? "green" : "red" }}>{row.UploadedStatus}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        <TablePagination
                                                            style={{ color: "#ffffff", boxShadow: "none", background: "#2b2d42" }}
                                                            className='paginationCLC'
                                                            rowsPerPageOptions={[]}
                                                            component="div"
                                                            count={ExcelSubmitResponse.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                        />
                                                    </Paper>
                                                </Grid> : null}</div> : null}
                                </>
                                : null}

                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};
export default CreateModule;
