import { SetStateAction, useEffect, useRef, useState } from 'react';
import {
    Checkbox,
    Container,
    FormControl,
    ListItemText,
    MenuItem,
    Grid,
    Select,
    Typography,
    Divider,
    TextField,
    Box
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import dateFormat from 'dateformat';
import { SnackBarOptions, Dateformat } from '../../../UserReport/DownloadableReport/CommonUtil';
import Attachments from '../../../../atoms/Attachments';
import DownArrow from '../../../../../assets/down-arrow.svg';
import Edit from '../../../../../assets/images/edit.svg';
import { Formik, Form, FormikProps, useFormikContext } from 'formik';
import useGetState from '../../../../utils/hooks/useGetState';
import CustomDateTimePicker from '../../../../atoms/CustomDateTimePicker';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import ACGButton from '../../../../atoms/Button';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import DropDown from '../../../../atoms/Dropdown';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import AcgTimePicker from '../../../../atoms/TimePicker';
import { fetchBlobImage } from '../../../../utils/helper';
import { convertBlobFileToBase64 } from '../../../../utils/helper';
import Axios from 'axios';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import moment from 'moment';
import { trackErrorsInNewRelic } from '../../../../utils/newRelicFuncations';

type ComponentProps = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    dtlData: any;
    selectedRow: any;
    isEdit: boolean;
    setDtlData: Function;
    setSelectedRow: Function;
    assetList: any;
    categoryList: any;
    actionTypeList: any;
    preventiveMaintenanceWorkOrderKey: any;
    getPreventiveMaintenanceWorkOrderList: Function;
    fetchPreventiveMaintenanceWorkOrderDetails: Function;
    getPreventiveMaintenanceList: Function;
    assignmentData: any;
};
type ModuleType = {
    startDateMO: any;
    startDateMC: any;
    scName: string | '';
    scValue: string | '';
    scDesc: string | '';
};

const CreateModule = (props: ComponentProps) => {
    const { preventiveMaintenanceWorkOrderKey, drawerClose, setCurrTab, setReload = () => { }, setSnackbarOpts = () => { }, dtlData, isEdit, setDtlData = () => { }, selectedRow, setSelectedRow = () => { }, assetList, categoryList, actionTypeList, getPreventiveMaintenanceWorkOrderList = () => { }, fetchPreventiveMaintenanceWorkOrderDetails = () => { }, assignmentData,getPreventiveMaintenanceList } = props;
    const formRef = useRef<any>(null);
    const [feedback, setFeedback] = useState('');
    const [maintenanceAction, setMaintenanceAction] = useState('');
    const [actionTypeKey, setActionTypeKey] = useState<number | string>('');
    const [partsNames, setPartsNames] = useState<any[]>([]);
    const [sparePartsList, setSparepartsList] = useState<any[]>([]);
    const [selectedModelImage, setSelectedModelImage] = useState<any>([]);
    const [formError, setFormError] = useState({ modelCode: '', modelName: '', paramsUpload: '', modelImage: '', hasError: false });
    const [initialValues, setInitialValues] = useState<any>({
        startDateMO: null,
        startDateMC: null,
        scName: '',
        scValue: '',
        scDesc: '',
    });
    const [editImage, setEditImage] = useState(false);
    const [fName, setFname] = useState('');
    const [onImageError,setOnImageError]=useState(false)
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const dispatch = useDispatch();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    const options = {
        AD_SUCCESS: {
            message: `Preventive Maintenance closed successfully!`,
            open: true,
            type: 'success'
        },
    };

    useEffect(() => {
        // getSpareParts(assignmentData[0]?.MaintPREVWorkOrderKey);
        setSelectedModelImage(dtlData[0]?.ImagPath);
    }, []);

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { handleSubmit() }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={maintenanceAction === "" ? true : false}
                />
            </div>
        );
    };

    const handleSubmit = async () => {
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.ADD_PREVENTIVE_MAINTENANCE_CLOSE_ORDER,
                requestType: 'POST',
                reqObj: {
                    userId: loggedInUser,
                    workOrderKey: assignmentData[0]?.MaintPREVWorkOrderKey,
                    maintenanceAction: maintenanceAction,
                }
            },
            uniqueScreenIdentifier: { prevMaintClose: true },
            storeKey: STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE_CLOSE_ORDER
        };
        dispatch(executeACGAction(bundle));
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    console.log(dtlData)

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE_CLOSE_ORDER]?.prevMaintClose) {
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE_CLOSE_ORDER,
                    uniqueScreenIdentifier: {
                        prevMaintClose: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE_CLOSE_ORDER]?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE_CLOSE_ORDER]?.prevMaintClose) {
            if (state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE_CLOSE_ORDER]?.status === "Success") {
                setReload(true);
                setCurrTab(1);
                setSnackbarOpts(options.AD_SUCCESS);
                drawerClose();
                preventiveMaintenanceWorkOrderKey !== '' ? fetchPreventiveMaintenanceWorkOrderDetails(preventiveMaintenanceWorkOrderKey) : undefined;
                getPreventiveMaintenanceWorkOrderList();
                getPreventiveMaintenanceList()
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE_CLOSE_ORDER,
                    uniqueScreenIdentifier: {
                        prevMaintClose: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_PREVENTIVE_MAINTENANCE_CLOSE_ORDER]?.prevMaintClose]);

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const handleChangeActionType = (event: { target: { value: any; }; }) => {
        setActionTypeKey(event.target.value);
    }
    const getSpareParts = async (sKey: number) => {
        let finalResult;
        const payload = {
            userId: loggedInUser,
            subComponentKey: sKey
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_BREAKDOWN_SPARE_PARTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setSparepartsList([
                        ...tmpTableData?.map((item: any) => ({ code: item.MaintSparePartKey, name: item.MaintSparePartName }))

                    ]);
                    finalResult = [...tmpTableData?.map((item: any) => ({ code: item.MaintSparePartKey, name: item.MaintSparePartName }))]
                }
            })
            .catch((error) => {
             trackErrorsInNewRelic({error: error})

                return {
                    status: error.response.status
                };
            });
        return finalResult;
    }
    const handleChangeSpareparts = (id: any) => {
        let newSelected: SetStateAction<any[]> = [];
        const selectedIndex = partsNames.findIndex((t) => t.code === id.code);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(partsNames, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(partsNames.slice(1));
        } else if (selectedIndex === partsNames.length - 1) {
            newSelected = newSelected.concat(partsNames.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                partsNames.slice(0, selectedIndex),
                partsNames.slice(selectedIndex + 1)
            );
        }
        setPartsNames(newSelected);
    }

    const DropDownIcon = (prop: any) => (
        <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
    );

    return (
        <Container className="createReport">
            <div className='inspectionConfig'>
                <p className="lineNameText" style={{ width: '100%', color: "#9cc2ff", fontSize: "12px", fontWeight: "bold", marginTop: "10px" }}>Machine SerialNo</p>
                <p className="lineCodeText" style={{ textAlign: 'left', color: "#b1c2df", fontSize: "12px", marginBottom: "15px" }}>{dtlData[0]?.MachineSerialNo}</p>
                <p className="lineNameText" style={{ width: '100%', color: "#9cc2ff", fontSize: "12px", fontWeight: "bold", marginTop: "10px" }}>Maintenance Description</p>
                <p className="lineCodeText" style={{ textAlign: 'left', color: "#b1c2df", fontSize: "12px", marginBottom: "15px" }}>{dtlData[0]?.MaintDesc}</p>
                <p className="lineNameText" style={{ width: '100%', color: "#9cc2ff", fontSize: "12px", fontWeight: "bold", marginTop: "10px" }}>Maintenance Started Time</p>
                <p className="lineCodeText" style={{ textAlign: 'left', color: "#b1c2df", fontSize: "12px", marginBottom: "15px" }}>{dtlData[0]?.MaintenanceStartDate}</p>
                <p className="lineNameText" style={{ width: '100%', color: "#9cc2ff", fontSize: "12px", fontWeight: "bold", marginTop: "10px" }}>Inspection check list Details</p>
                <div style={{ display: "flex", marginTop: "20px" }} className='inspectionConfigHead'>
                    <span className='taskHeaders'>Task Id</span>
                    <span className='taskHeaders'>Task description</span>
                    <span className='taskHeaders'>Inspection Date</span>
                    <span className='taskHeaders'>Inspection Remarks</span>

                </div>
                {/* <Divider style={{ backgroundColor: "rgba(58, 68, 86, 0.6)" }} /> */}
                {dtlData?.map((form: any, index: number) => {
                    return (

                        <div key={index} className='inspectionConfigBody'>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }}>
                                <span className="taskLabel"> Task Id- {index + 1}</span>
                                <span className="taskLabel">{form?.TaskDesc}</span>
                                <span className="taskLabel">{form?.InspectionDate ? moment.utc(form?.InspectionDate).format("DD/MM/YYYY") : "-"}</span>
                                <span className="taskLabel">{form?.InspRemark ? form?.InspRemark : "-"}</span>
                            </Box>

                        </div>

                    )
                })
                }

            </div>
            {!onImageError?<img
                alt="No image"
                src={dtlData.length > 0 ? fetchBlobImage(dtlData[0].ImagePath).url : undefined}
                width={"100%"}
                style={{ marginTop: 10, color: "#ffffff" }}
                onError={()=>{
                    setOnImageError(true)
                }}
            />:null}
            
            <Formik
                initialValues={initialValues}
                onSubmit={(values: any) => {
                    // handleSubmit(values);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: FormikProps<ModuleType>) => {
                    const { values, handleChange, handleBlur, errors, touched, setFieldValue } = formikprops;
                    return (
                        <Form>
                            <Grid width={"50%"}>
                                <Label label="Closure Remarks &nbsp; *" className={'labelDiv'} />
                                <TextInput
                                    variant="outlined"
                                    type="text"
                                    name="gtId"
                                    fullWidth={true}
                                    value={maintenanceAction}
                                    autoComplete="off"
                                    formInput="inputDiv"
                                    placeholder='Closure Remarks'
                                    onChange={(e: any) => {
                                        setMaintenanceAction(e.target.value)
                                    }}
                                    maxLength={300}
                                />

                                <Typography variant="body1" sx={{ color: "#ffff", marginTop: 2 }}>Note: * fields are mandatory! </Typography>
                            </Grid>
                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};
export default CreateModule;
